/* eslint-disable import/first */
window.eosNameSent = false;
window.readyToFetchInfoLoaded = false;

// eslint-disable-next-line no-restricted-globals
if (location.protocol !== "https:") {
  // eslint-disable-next-line no-restricted-globals
  location.protocol = "https:";
}

import getYup from './getYup';
getYup();
(async () => {
  
  document.addEventListener('eosNameLoaded', async function(e) {
    
    if(!e) return;  
  
      const eosName =  e.detail.eosname;
      
      const hosts = [ 
        'eos.hyperion.eosrio.io',
        'api.eosrio.io',
  ];
  
  
 
  const votesUrl = 'https://api.yup.io/votes/voter/' + eosName;
  
  
  const postUrlfun = (num) =>  
  num  < hosts.length ?
  'https://'+hosts[num]+'/v2/history/get_actions?account='+eosName+'&filter=*%3Apostvotev2&skip=0&limit=10&sort=desc'
  :'https://'+hosts[0]+'/v2/history/get_actions?account='+eosName+'&filter=*%3Apostvotev2&skip=0&limit=10&sort=desc';
  
  const transfersUrlfun = (num) => 
  num  < hosts.length ?
  'https://'+hosts[num]+'/v2/history/get_actions?account='+eosName+'&filter=*%3Atransfer&skip=2&limit=10&sort=desc'
  :'https://'+hosts[0]+'/v2/history/get_actions?account='+eosName+'&filter=*%3Atransfer&skip=2&limit=10&sort=desc';
  
  
  let retry = 0;
  let fetchTry = true;
  
  let accountsJson;
  if(!e.detail.accounts){
  const accountUrl = `https://api.yup.io/accounts/${eosName}`;
  const accounts = await fetch(accountUrl);
  
  
  if (accounts.ok ) {
  accountsJson = await accounts.json();
  }
}else{
  accountsJson = e.detail.accounts;
}
  
  const votes = await fetch(votesUrl);
  let votesJson;
  if (votes.ok){
  votesJson = await votes.json();
  }
  
  
  let posts;
  do{
  try{
  posts = await fetch(postUrlfun(retry) );
  }
  catch(e){
  await new Promise(r => setTimeout( _ => r(), 3500));
  fetchTry = false;
  retry++;
  if(retry >= hosts.length){
  retry = 0;
  }
  }
  }while(!fetchTry);
  
  let postsJson;
  if (posts.ok){
  postsJson = await posts.json();
  }
  
  retry = 0;
  
  let transfers;
  
  do{
  try{
  await new Promise(r => setTimeout( _ => r(), 250));
  transfers = await fetch(transfersUrlfun(retry));
  }
  catch(e){
  await new Promise(r => setTimeout( _ => r(), 2000));
  fetchTry = false;
  retry++;
  if(retry >= hosts.length){
  retry = 0;
  }
  }
  }while(!fetchTry);
  
  
  let recentTransfers;
  if (transfers.ok){
  recentTransfers = await transfers.json();
  }
 
  
  const stopLoading = new CustomEvent('setloading', {
  detail: { loading: false,
  data: {
  votes: votesJson,
  posts: postsJson,
  transfers: recentTransfers,
  account: accountsJson,
  }
  
  },
  });
  document.dispatchEvent(stopLoading);
  
  
  
    });
    
  const redyTofetchInfo = new Event('readyToFetchInfo');
  while(window.eosNameSent === false){
    document.dispatchEvent(redyTofetchInfo);
    await new Promise(r => setTimeout( _ => r(), 50));
  }
  
  }
  )() ;
  

  
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
