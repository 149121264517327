 /* eslint-disable import/first */
 
 
import React from 'react';
import { makeStyles, useTheme, createTheme, ThemeProvider   } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';


import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import { deepOrange } from '@material-ui/core/colors';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Parser from 'html-react-parser';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { useSpring, animated } from 'react-spring';


import './App.scss';

const theme = createTheme({
  palette: {
    primary: {
      main: '#d2f7ea',
    },
    secondary: {
      main:'#6dedbe',
    },
  },
});
 

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div" >{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#1d222b',
    width: '100%',
  },
}));

function FullWidthTabs(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const { loading, fdata } = props;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <AppBar style={ {backgroundColor:"#03a9f4" } } position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="full width tabs example"
          centered
        >
          <Tab label="Recent Transactions" {...a11yProps(0)} />
          <Tab label="Recent Posts" {...a11yProps(1)} />
          <Tab label="Recent Votes" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
        style={{ textAlign: loading? 'center' :' left' }}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
        {loading ?
            <CircularProgress size={100} thickness={2} />
          :
          fdata.transfers.map((t, i) => (
            <Box style={{ marginBottom:'1.5rem', marginLeft:'3rem' }} key={i}>
            <Box><b style={{ marginRight:'2.43rem' }} >Date:</b>{t.date.toDateString()+ " - "+t.date.toLocaleTimeString() } </Box>
            <Box><b style={{ marginRight:'1rem' }} >Amount:</b>{ t.amount + ' ' + t.symbol } </Box>
            <Box><b style={{ marginRight:'3.5rem' }} >To:</b>{ t.to } </Box>
            <Box><b style={{ marginRight:'2.3rem' }} >From:</b>{ t.from } </Box>
            <Box><b style={{ marginRight:'1.7rem' }} >Memo:</b>{ t.memo } </Box>
            </Box>
          ))
          }
          
 
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
        {loading ?
             <CircularProgress size={100} thickness={2} />
             :
             fdata.posts.map((p, i) => (
              <Box style={{ marginBottom:'1.5rem', marginLeft:'.5rem' }} key={i}>
              <Box><b style={{ marginRight:'.5rem' }} >Date:</b>{p.date.toDateString()+ " - "+p.date.toLocaleTimeString() } </Box>
              <Box><b style={{ marginRight:'.5rem' }} >Url:</b>{ p.url} </Box>
              <Box><b style={{ marginRight:'.5rem' }} >Category:</b>{ p.category } </Box>
              <Box><b style={{ marginRight:'.5rem' }} >Tag:</b>{ p.tag } </Box>
              </Box>
            ))
          }
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
        {loading ?
            <CircularProgress size={100} thickness={2} />
            : 
            fdata.votes.map((v, i) => (
             <Box style={{ marginBottom:'1.5rem', marginLeft:'.5rem' }} key={i}>
             <Box><b style={{ marginRight:'.5rem' }} >Date:</b>{ v.date } </Box>
             <Box><b style={{ marginRight:'.5rem' }} >Category:</b>{ v.category } </Box>
             <Box><b style={{ marginRight:'.5rem' }} >Voteid:</b>{ v.voteid } </Box>
             <Box><b style={{ marginRight:'.5rem' }} >Tag:</b>{ v.postid } </Box>
             <Box><b style={{ marginRight:'.5rem' }} >Comp Key:</b>{ v.comp_key } </Box>
             </Box>
           ))

          } 
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}


const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};


function CounterModal(props) {
  const [count, setCount] = React.useState(props.count);

  document.addEventListener('setModalCounter', function(e) {
     setCount(e.detail);
  });

  
  return (
    <span style={{ fontWeight:'500' }}>{count}</span>
  );

}


function App() {

  const [modalOpen, setModalOpen] = React.useState(false);
  
  const [modalData, setModalData] = React.useState(
       {
         title: '',
         message: '',
         redirectTime: 0,
         open:false,
       }
  );


  const closeModal = () => {
    //setModalOpen(false);
    // don't close the modal
  };

  const [loading, setLoading] = React.useState(true);

  const [frontData, setFrontDot] = React.useState(
    { 
      eosname: '',
      username: '',
      influence: '',
      bio: '',
      fullname: '',
      avatar: '',
      //...
      votes: [],
      posts: [],
      recentTransfers: [],
    }

  );


  async function processData(data){

    
    const posts = [];
    const recentTransfers = [];
    const votes = [];

    
    data.votes.forEach((v) => {
      const computedDate = (new Date(Number(v.timestamp))).toDateString()+ " - "+ (new Date(Number(v.timestamp))).toLocaleTimeString()
      v.date = computedDate;
      votes.push(v);
    });


    data.posts.actions.forEach( (post) => {
       const postObj = {
        date: new Date(post.timestamp),
        url: post.act.data.caption,
        category: post.act.data.category,
        tag: post.act.data.tag,
       }
       posts.push(postObj);
    });

    data.transfers.actions.forEach( (transfer) => {
      const transferObj = {
        date: new Date(transfer.timestamp),
        amount: transfer.act.data.amount,
        memo: transfer.act.data.memo,
        from: transfer.act.data.from,
        to: transfer.act.data.to,
        symbol: transfer.act.data.symbol,
      }
      recentTransfers.push(transferObj);
    });


    const nextState = { 
      eosname: data.account.eosname,
      username: data.account.username,
      influence: Number(data.account.weight).toFixed(0),
      bio: data.account.bio,
      fullname: data.account.fullname,
      avatar: data.account.avatar,

      votes: votes,
      posts: posts,
      transfers: recentTransfers,
    }

    setFrontDot((prev) => {
      return {...prev, ...nextState };
    }
    );

  }
   function actionSetLoading (val){
    setLoading(val);
  };

  function redirectTimer(num){
      if(num === 0){
        window.location.replace('https://app.yup.io');
        return;
      }else{
      const setCounterEvent = new CustomEvent('setModalCounter', { detail: num-1 });
      document.dispatchEvent(setCounterEvent);
      setTimeout( _ => redirectTimer(num-1), 950 );
     }
  }
 

  document.addEventListener('setloading', async function(e) {
    await processData(e.detail.data);
    actionSetLoading(e.detail.loading);
    
    document.addEventListener('operateModal', async (e) => {
      
      setModalData((prevState) => {
       return { ...prevState, ...e.detail };
      });
      if(e.detail.open) setModalOpen(true);
      redirectTimer(e.detail.redirectTime);
    }); 
  });

  const gridBase = {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '1rem',
  };

  const paperBase = {
    padding: theme.spacing(2),
    textAlign: 'center',
    margin: 'auto',
    backgroundColor: '#1d222b',
    color: 'white',
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    grid:{
      ...gridBase,
      maxWidth: '20rem'
    },
    grid2: {
      ...gridBase,
      maxWidth: '40rem'
    },
    paper1: {
      ...paperBase,
      minHeight: '8rem',
    },

    paper2: {
      ...paperBase,
      minHeight: '20rem',
    },
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
    },

    gray: {
      backgroundColor: '#555',
    },

    logo: {
      backgroundColor: '#555',
      width:'5rem',
      height:'5rem',
      margin: 'auto'
    },

    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalPaper: {
      backgroundColor: '#212121',
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },

  }));
  
   
  const classes = useStyles();

  return (
    <div  style={{
      opacity: loading ? '0.6' : '1'
    }} className="App">
       <ThemeProvider theme={theme}>
          <Grid className={classes.grid} item xs={6}>
          

          <Grid container spacing={3}>
        <Grid style={{ marginBottom:'.5rem' }}item xs={12}>
        <Avatar  alt='logo' src='/logo192.png' className={classes.logo}>
          L
        </Avatar>
        </Grid>
          </Grid>



          <Grid container spacing={3}>
        <Grid style={{ marginBottom:'1rem' }}item xs={12}>
          Yup Account: <b>{ frontData.username }</b>
        </Grid>
          </Grid>
          
          {loading &&
            <LinearProgress color="secondary" />
          }{ loading ? <Paper className={classes.paper1}> <CircularProgress /> </Paper>:
          <Paper className={classes.paper1}>
      
      
      <div className={classes.demo}>
            <List dense={false}>
                <ListItem>
                  <ListItemAvatar style={{ width:'100%' }}> 
                  <Avatar style={{ margin:'auto'}} alt={ frontData.eosname } src={frontData.avatar} className={classes.orange}>
                     { frontData.eosname && frontData.eosname.charAt(0).toUpperCase() }
                </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={null}
                    secondary={null}
                  />
                </ListItem>
                {  frontData.fullname &&
                <ListItem>
                  <ListItemAvatar>
                  <Avatar className={classes.gray}>
                  ⚪
                </Avatar>
                  </ListItemAvatar>
                  <ListItemText

                    

                    primary={Parser('<b>Full Name: </b>' + frontData.fullname)}
                    secondary={null}
                  />
                </ListItem>
         }      

                <ListItem>
                  <ListItemAvatar>
                  <Avatar className={classes.gray}>
                  ⚪
                </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={Parser('<b>Eos Name: </b>' + frontData.eosname)}
                    secondary={null}
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                  <Avatar className={classes.gray}>
                  ⚪
                </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={ Parser('<b>Username: </b>' + frontData.username)}
                    secondary={null}
                  />
                </ListItem>
                { frontData.bio &&
                <ListItem>
                  <ListItemAvatar>
                  <Avatar className={classes.gray}>
                  ⚪
                </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={Parser('<b>Bio: </b>' + frontData.bio)}
                    secondary={null}
                  />
                </ListItem>
        }
                <ListItem>
                  <ListItemAvatar>
                  <Avatar className={classes.gray}>
                  ⚪
                </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={Parser('<b>Influence: </b>' + frontData.influence)}
                    secondary={null}
                  />
                </ListItem>
            </List>
          </div>
          </Paper>
      }
          </Grid>
         
        <Grid className={classes.grid2} item xs={6}>
          <Paper className={classes.paper2}>
            <FullWidthTabs loading={ loading } fdata={frontData} />
          </Paper>
        </Grid>
 
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={modalOpen}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalOpen}>
        <div className={classes.modalPaper}>
            <h2 id="transition-modal-title">{ modalData.title }</h2>
            <p id="transition-modal-description">
              { modalData.message }
            </p>
            <CircularProgress 
            style={
           {
            width: '5rem',
            height: '1rem',
            paddingTop: '3rem',
            paddingBottom: '3rem',
            marginLeft: '40%',
           } }
            />
            <p> Redirecting in <CounterModal  count={modalData.redirectTime}/> seconds...</p>
          </div>
        </Fade>
      </Modal>

        </ThemeProvider>
    </div>
  );
}

export default App;
